<template>
  <div class="step----page">
    <div class="step-content">
      <img class="step-image" :src="config.image" alt />
      <div :class="{'step-message': true, [config.className]: true}">{{ config.label }}</div>
      <div class="step-message error" v-if="orderData === 6">未通过原因：大地保险未通过</div>
      <div class="page-btn" @click="open = !open">
        {{ open ? '收回' : '打开' }}打款信息
        <a-icon :style="iconStyle" type="up" />
      </div>
      <div class="transtion-box" :style="infoStyle">
        <div class="transfer-info border-box">
          <div class="label-content">
            <div class="info-label">汇款金额：</div>
            <div class="info-label">对公银行账户号：</div>
            <div class="info-label">账户主体：</div>
            <div class="info-label">电话：</div>
            <div class="info-label">开户行信息：</div>
            <div class="info-label">开户行行号：</div>
          </div>
          <div class="value-content">
            <div class="info-value">{{ orderData.sumPremium }}元</div>
            <div class="info-value">5329 1314 4010 366</div>
            <div class="info-value">鼎基智能云平台有限公司</div>
            <div class="info-value">0532-55786657</div>
            <div class="info-value">招商银行青岛国际创新园支行</div>
            <div class="info-value">3084 5202 5510</div>
          </div>
          <div class="copy-btn" @click="copy">复制打款信息</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import success from './image/success.png'
import error from './image/error.png'
import loading from './image/loading.png'

const pngConfig = [
  {
    insuranceStatus: [3, 4],
    image: loading,
    label: '核实中信息，请稍等...',
    className: 'loading'
  },
  {
    insuranceStatus: [5],
    image: success,
    label: '恭喜您，保险购买成功！',
    className: 'success'
  },
  {
    insuranceStatus: [6, 7],
    image: error,
    label: '抱歉，您的保险购买申请未通过！',
    className: 'error'
  }
]

export default {
  name: 'StepPage',
  inject: ['handleCopy', 'getOrderData'],
  data () {
    return {
      open: true
    }
  },
  computed: {
    orderData () {
      return this.getOrderData()
    },
    config () {
      return pngConfig.find(item => item.insuranceStatus.includes(this.orderData.insuranceStatus))
    },
    infoStyle () {
      return {
        height: this.open ? '189px' : '0px'
      }
    },
    iconStyle () {
      return {
        transform: `rotate(${this.open ? 0 : 180}deg)`,
        transition: 'all 0.5s'
      }
    }
  },
  methods: {
    copy () {
      const text = `汇款金额：${this.orderData.sumPremium}元, 对公银行账户号：5329 1314 4010 366, 账户主体：鼎基智能云平台有限公司, 电话：0532-55786657, 开户行信息：招商银行青岛国际创新园支行, 开户行行号：3084 5202 5510`
      this.handleCopy(text)
    }
  }
}
</script>

<style lang="less">
.step----page {
  height: 100%;
  .step-content {
    width: 820px;
    margin: 30px auto;
    text-align: center;
  }
  .step-image {
    width: 225px;
    height: 225px;
  }
  .step-message {
    margin: 0 0 20px;
    &.loading {
      color: rgba(0, 93, 255, 1);
    }
    &.success {
      color: rgba(92, 184, 122, 1);
    }
    &.error {
      color: rgba(244, 68, 76, 1);
    }
  }

  .page-btn {
    margin: 10px 0;
    cursor: pointer;
  }
  .transtion-box {
    transition: height 0.5s;
    overflow: hidden;
  }
  .transfer-info {
    display: flex;
    border: 1px solid #c4c4c4;
    align-items: baseline;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 400;
    color: #303133;

    .label-content {
      margin-bottom: 13px;
      width: 130px;
      text-align: right;
      font-weight: 500;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .value-content {
      margin-bottom: 13px;
      text-align: left;
      flex: 1;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .copy-btn {
      color: #3d77ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
